import React from "react";
import CV from "../../assets/cv.pdf";

const CTA = () => {
  return (
    <div className='cta'>
      <a href={CV} download='Sri_Vishnu_SSE_CV' className='btn'>
        Here's my CV
      </a>
      <a href='#contact' className='btn btn-primary'>
        Let's talk
      </a>
    </div>
  );
};

export default CTA;
