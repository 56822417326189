import React from "react";
import "./workex.css";
import spinsci from "../../assets/spinsci.jpg";
import scaler from "../../assets/scaler.jpg";
import validus from "../../assets/validus.jpg";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    logo: spinsci,
    Name: "Spinsci",
    work: "I developed a chatbot widget with a React frontend and Node.js backend for seamless website integration, guiding users through interactive flows. Using Google Dialogflow CX, the chatbot supports multiple chatflows and handles natural language input. I implemented Node.js webhooks for business logic, deploying them as Cloud Run functions on Google Cloud Platform (GCP), and created a Node.js backend to manage authentication and Dialogflow agent integration.",
  },
  {
    logo: validus,
    Name: "validus",
    work: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore tempora, incidunt aliquam labore, est quo accusantium beatae magnam dicta velit perspiciatis dolor praesentium optio, recusandae reprehenderit cupiditate? Id, eligendi repellat!",
  },

  {
    logo: scaler,
    Name: "Scaler Academy",
    work: "I developed a chatbot widget with a React frontend and Node.js backend for seamless website integration, guiding users through interactive flows. Using Google Dialogflow CX, the chatbot supports multiple chatflows and handles natural language input. I implemented Node.js webhooks for business logic, deploying them as Cloud Run functions on Google Cloud Platform (GCP), and created a Node.js backend to manage authentication and Dialogflow agent integration.",
  },
];
const Workex = () => {
  return (
    <section id='Workex'>
      <h5>
        This Section includes my work experience in both tech and non-tech roles
      </h5>
      <h2>Work Experience</h2>

      <Swiper
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ dynamicBullets: true, clickable: true }}
        className='container workex__container'>
        {data.map(({ logo, Name, work }, index) => {
          return (
            <SwiperSlide key={index} className='workex'>
              <div style={{ minHeight: 400 }}>
                <div className='workex__logo'>
                  <img src={logo} alt='Company logo' />
                </div>
                <h5 className='workex__name'>{Name}</h5>
                <small className='workex__work'>{work}</small>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Workex;
